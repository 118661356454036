<template>
  <div class="modal_product">
    <div class="top">
      <div class="top_info large txt-dark_gray-1">
        {{ seriesMain(product) }}/{{ seriesSub(product) }}
      </div>
    </div>
    <div class="bottom">
      <div class="product_name txt-black h3">
        {{ convertToEnglish(product.name, product.name_en) }}
      </div>
      <div class="p txt-red">{{ product.no }}</div>
      <div class="product_item_pic">
        <Magnifier
          v-if="isInit && activePic && !isMobile"
          class="product_item_pic_magnifier product_item_pic-pc"
          :src="activePic"
          :srcLarge="activePic"
        />
        <div
          v-if="isMobile"
          class="product_item_pic_render product_item_pic-pc"
          :style="{ backgroundImage: `url('${activePic}')` }"
        ></div>
      </div>
      <div class="product_detail txt-center">
        <div class="p">Size: {{ product.size }}</div>
        <div v-if="colorList[activeName]" class="p">
          Color: {{ convertToEnglish(colorList[activeName].name, colorList[activeName].name_en) }}
        </div>
        <div v-if="product.weight" class="p">
          Note: {{ convertToEnglish(product.weight, product.weight_eng) }}</div>
        <ZoomIn
          class="zoom_btn zoomIn_btn"
          @click.native="zoomIn()"
        />
      </div>
      <ColorPicker
        class="txt-center"
        :colors="product.colors"
        :initActiveIdx="initActiveIdx"
        @updateColorActiveIdx="updateColorActiveIdx"
      />
    </div>
    <div
      v-if="product.blade_pic"
      class="blade_pic_wrapper"
    >
      <div
        class="blade_pic"
        :style="{ backgroundImage: `url('${product.blade_pic}')` }"
      ></div>
      <div class="small nowrap">刀鋒示意圖</div>
    </div>

    <div class="zoomIn_box" :class="{ active: isZoomIn }">
      <ZoomOut
        class="zoom_btn zoomOut_btn"
        @click.native="zoomOut()"
      />
      <div class="overflow_box noScrollBar">
        <div class="product_wrapper">
          <img :src="activePic" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { colorList } from '@/lib/const';
import ColorPicker from '@/components/colorPicker/Index.vue';
import Magnifier from '@/components/magnifier/Index.vue';
import ZoomIn from '@/components/icon/ZoomIn.vue';
import ZoomOut from '@/components/icon/ZoomOut.vue';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'ModalProduct',
  mixins: [languageMixin],
  props: {
    series: {
      type: Object,
      default() {
        return {};
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    initActiveIdx: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ColorPicker,
    Magnifier,
    ZoomIn,
    ZoomOut,
  },
  data() {
    return {
      isInit: false,
      isMobile: false,
      isZoomIn: false,
      colorList,
      activeIdx: 0,
    };
  },
  computed: {
    activeColor() {
      return this.product.colors?.[this.activeIdx] || {};
    },
    activePic() {
      return this.activeColor?.pic || '';
    },
    activeName() {
      return this.activeColor?.name || '';
    },
  },
  methods: {
    updateColorActiveIdx(idx) {
      this.activeIdx = idx;
    },
    zoomIn() {
      this.isZoomIn = true;
      this.$emit('zoomUpdate', this.isZoomIn);
    },
    zoomOut() {
      this.isZoomIn = false;
      this.$emit('zoomUpdate', this.isZoomIn);
    },
    seriesMain(product) {
      return this.convertToEnglish(product.lv1, product.lv1_en);
    },
    seriesSub(product) {
      return this.convertToEnglish(product.lv2, product.lv2_en);
    },
  },
  created() {
    this.activeIdx = this.initActiveIdx;
  },
  mounted() {
    this.isInit = true;

    if (window.screen.width >= 1023) this.isMobile = false;
    else this.isMobile = true;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.modal_product {
  position: relative;
  padding: 0 5% 5%;
  .top {
    padding-bottom: .5rem;
    border-bottom: 1px solid $color-light_gray;
    &_top {
      padding-bottom: 1rem;
      border-bottom: 1px solid #000;
    }
  }
  .bottom {
    padding-top: 1rem;
    .product_item_pic {
      width: 80%;
      margin: 0 auto;
      .product_item_pic_render {
        padding-bottom: 30%;
        background: center/contain no-repeat;
      }
      &_magnifier {
        width: 100%;
      }
    }
    .product_detail {
      position: relative;
      padding: 2rem 0;
      .zoomIn_btn {
        display: none;
        width: 45px;
        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .blade_pic_wrapper {
    position: absolute;
    bottom: 10%;
    right: 5%;
    width: 60px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blade_pic {
      width: 100%;
      padding-bottom: 100%;
      background: center/contain no-repeat;
    }
  }
  .zoomIn_box {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    overflow: auto;
    .zoomOut_btn {
      position: absolute;
      top: 3%;
      right: 3%;
      width: 45px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    .overflow_box {
      height: 100%;
    }
    .product_wrapper {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 200vw;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    &.active {
      display: block;
    }
  }
  @media screen and (max-width: 1023px) {
    padding: 0;
    .top {
      padding: 0 5%;
    }
    .bottom {
      padding: 20px 5% 5%;
      .product_name {
        font-size: 2rem;
      }
      .product_item_pic {
        margin-top: 10%;
        width: 100%;
      }
      .product_detail {
        margin-top: 2rem;
        padding-top: 4rem;
        .zoomIn_btn {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .blade_pic_wrapper {
      position: relative;
      bottom: auto;
      right: auto;
      width: 100%;
      margin: 0 auto;
      padding: 30px 0;
      background-color: $color-gray;
      .blade_pic {
        width: 100px;
        padding-bottom: 100px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
